<template>
  <div class="task_list">
    <div class="main">
      <div class="top_box_search">
        <div class="search">
          <el-input
            v-model="search_value"
            @keyup.enter.native="search_notice"
            placeholder="请输入内容"></el-input>
          <div class="search_box">
            <i class="el-icon-search" @click="search_notice"></i>
          </div>
        </div>
        <div class="new_add" @click="assignment">布置作业</div>
      </div>
      <ul class="task_status">
        <li
          v-for="item in task_status_list"
          :class="{ active: status_active === item.id }"
          :key="item.id"
          @click="change_status(item.id)">
          {{ item.name }}
        </li>
      </ul>
      <main>
        <ul>
          <li
            v-for="item in taskList"
            :key="item.id"
            @click="task_detail(item.id)">
            <div class="top_box">
              <div class="status_box" v-if="item.status === 1">进行中</div>
              <div class="status_box ash" v-else-if="item.status === 2">
                已结束
              </div>
              <div class="status_box ash" v-else-if="item.status === 0">
                未开始
              </div>
              <p class="one-txt-cut">
                {{ item.start_time }} - {{ item.end_time }}
              </p>
            </div>
            <div class="center_box">
              <div class="left_box">
                <p class="p1 one-txt-cut">{{ item.name }}</p>
                <p class="p2 one-txt-cut">班级：{{ item.classes.name }}</p>
              </div>
              <div class="right_box">
                <p>完成率</p>
                <el-progress
                  type="circle"
                  :percentage="item.rate"
                  :stroke-width="15"
                  text-color="#2BC571"
                  color="#2BC571">
                </el-progress>
              </div>
            </div>
          </li>
        </ul>
      </main>
      <el-pagination
        background
        layout="prev, pager, next"
        :page-size="per_page"
        :total="total"
        @current-change="currentPage">
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      per_page: 9,
      total: 0,
      page: 1,
      search_value: "",
      task_status_list: [
        { id: 0, name: "全部" },
        { id: 1, name: "进行中" },
        { id: 2, name: "已结束" },
      ],
      status_active: 0,
      taskList: [],
    };
  },
  created() {
    this.get_task();
  },
  methods: {
    currentPage(val) {
      this.page = val;
      this.get_task();
    },
    search_notice() {
      this.page = 1;
      this.get_task();
    },
    change_status(id) {
      this.status_active = id;
      this.get_task();
    },
    async get_task() {
      let status_active = "";

      if (this.status_active != 0) {
        status_active = this.status_active;
      }
      const res = await this.axios.get(
        `/homework?status=${status_active}&page=${this.page}&per_page=${this.per_page}&name=${this.search_value}`
      );

      if (res.code === 1) {
        this.taskList = res.data.data;
        this.total = res.data.total;
      }
    },
    assignment() {
      this.$router.push({ path: "/assignment" });
    },
    task_detail(id) {
      this.$router.push({ path: `/taskdetail?id=${id}` });
    },
  },
};
</script>

<style lang="less" scoped>
.task_list {
}
.main {
  flex: 1;
  background-color: #fff;
  border-radius: 4px;
  padding: 35px 25px;
  box-sizing: border-box;
  width: 100%;
  min-height: 100%;
}
.el-pagination {
  // position: absolute;
  // right: 40px;
  // bottom: 50px;
  float: right !important;
  margin-top: 40px !important;
}
.task_list {
  // width: 100%;
  // height: 100%;
  // // background: #fff;
  // border-radius: 20px;
  // padding: 20px;
  // box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: stretch;
  padding: 20px;
  box-sizing: border-box;
  min-height: 100%;
  .title {
    font-size: 20px;
    font-weight: normal;
    color: #5fa7ff;
  }
  .top_box_search {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    .search {
      position: relative;
      width: 327px;
      margin-top: 20px;
      margin-bottom: 20px;
      .search_box {
        width: 40px;
        height: 40px;
        background: #516cf7;
        border-radius: 10px;
        position: absolute;
        right: 0;
        top: 0;
        i {
          width: 100%;
          font-size: 20px;
          text-align: center;
          line-height: 40px;
          color: #fff;
        }
      }
    }
    .new_add {
      width: 100px;
      height: 40px;
      background: #516cf7;
      border-radius: 10px;
      text-align: center;
      font-size: 15px;
      color: #fff;
      line-height: 41px;
    }
  }
  .task_status {
    display: flex;
    li {
      height: 30px;
      font-size: 15px;
      color: #fff;
      padding-left: 10px;
      padding-right: 10px;
      background: #cccccc;
      margin-right: 20px;
      line-height: 30px;
      border-radius: 10px;
      cursor: pointer;
    }
    .active {
      background: #516cf7;
    }
  }
  main {
    padding-top: 43px;
    padding-left: 10px;
    padding-right: 10px;
    max-height: 73%;
    overflow-y: auto;
    ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      li {
        width: 450px;
        // height: 230px;
        border-radius: 10px;
        padding-bottom: 10px;
        background: #fff;
        margin-bottom: 30px;
        box-shadow: 0px 0px 8px 2px rgba(175, 157, 157, 0.81);
        overflow: hidden;
        transition: 0.5s all;
        .top_box {
          display: flex;
          justify-content: space-between;
          align-content: center;
          .status_box {
            width: 90px;
            height: 20px;
            border-radius: 10px 0px 60px 0px;
            background: #516cf7;
            text-align: center;
            line-height: 20px;
            color: #fff;
            font-size: 12px;
          }
          .ash {
            background: #cccccc;
          }
          p {
            font-size: 12px;
            color: #999999;
            margin-right: 10px;
            line-height: 20px;
          }
        }
        .center_box {
          display: flex;
          align-items: center;
          margin-top: 10px;
          .left_box {
            width: 40%;
            padding-left: 10px;
            // padding-top: 20px;
            .p1 {
              font-size: 14px;
              color: #666666;
            }
            .p2 {
              font-size: 14px;
              color: #999999;
              margin-top: 10px;
            }
          }
          .right_box {
            display: flex;
            align-items: center;
            padding-left: 20px;
            p {
              color: #2bc571;
              font-size: 20px;
              margin-right: 20px;
              // width: 100px;
            }
          }
        }
      }
      li:hover {
        transform: scale(1.01);
      }
    }
  }
}
</style>
